import styled from 'styled-components'
import { Button } from 'ui'

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Label = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.text};
  margin-top: 16px;
  margin-bottom: 8px;
`

export const Info = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.hint};
  margin-bottom: 24px;
`

export const BtnWrapper = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.color.btn};
`
