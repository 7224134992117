import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 16px;
  gap: 16px;
`

export const BannerImage = styled.img`
  width: 100%;
  margin: 24px auto;
`

export const ButtonsWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

// export const LogoWrapper = styled.div`
//   background: url(${mainLogo}) no-repeat;
//   height: 118px;
//   background-size: contain;
// `

export const Title = styled.div`
  font-size: 34px;
  line-height: 40px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.text};
`

export const Biggest = styled.span`
  font-weight: 800;
`

export const Description = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  color: ${({ theme }) => theme.color.text};
  display: flex;
  align-items: center;
  gap: 6px;
  svg {
    width: 23px;
    height: 23px;
  }
`

// export const BtnWrapper = styled(Button)`
//   margin-top: 10px;
//   background: ${({ theme }) => theme.color.btn};
// `

export const Info = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0;
  color: ${({ theme }) => theme.color.hint};
`