import { FC } from 'react'
import { Outlet } from 'react-router-dom'
// import { Splash } from 'features/Splash'
import { Navbar } from './components'

import * as S from './style'

export const Layout: FC = () => (
  <>
    {/* <Splash /> */}
    <S.Wrapper>
      <S.ContentWrapper>
        <Outlet />
      </S.ContentWrapper>
      <Navbar />
    </S.Wrapper>
  </>
)

