import { AXIOS_INSTANCE } from 'libs/axios-instance/axios-instance'
import * as ApiTypes from './types'
import { ApiRoutes } from 'constants/api'

export const getTopTokensList = async () => {
    const { data } = await AXIOS_INSTANCE.get<ApiTypes.TopToken[]>(
        `${ApiRoutes.TopTokensList}`
    )
    return data
}

export const getTopToken = async (params: { tick: string }) => {
    const { data } = await AXIOS_INSTANCE.get<ApiTypes.TopToken>(
        `${ApiRoutes.TopTokensList}/${params.tick}`
    )
    return data
}

export const getSearchedTokensList = async (params: { query: string }) => {
    if (!params.query) return [];
    const { data } = await AXIOS_INSTANCE.get<ApiTypes.TopToken[]>(
        `${ApiRoutes.SearchTokens}`,
        { params }
    )
    return data
}