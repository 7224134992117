import styled from 'styled-components'
import { Button } from 'ui'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.color.text};
`

export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const RadioButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const RadioButtonsIndex = styled.span`
`

export const Desc = styled.p`
  color: ${({ theme }) => theme.color.grey};
  margin-bottom: 24px;
`

export const BtnWrapper = styled(Button)`
  width: 100%;
  margin-top: 24px;
  background: ${({ theme }) => theme.color.btn};
`