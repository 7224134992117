import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 25 24"
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.9199 2.84016L4.5299 7.04016C3.6299 7.74016 2.8999 9.23016 2.8999 10.3602V17.7702C2.8999 20.0902 4.7899 21.9902 7.1099 21.9902H18.6899C21.0099 21.9902 22.8999 20.0902 22.8999 17.7802V10.5002C22.8999 9.29016 22.0899 7.74016 21.0999 7.05016L14.9199 2.72016C13.5199 1.74016 11.2699 1.79016 9.9199 2.84016Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M12.8999 17.9902V14.9902"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
)
const Memo = memo(SvgHome)
export default Memo
