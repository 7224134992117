import { FC } from 'react'
import { SvgPriceDown, SvgPriceUp } from 'ui/icons'
import * as S from './style'

type PriceChangeProps = {
  percent: number,
  isGreen?: boolean,
}

export const PriceChange: FC<PriceChangeProps> = (props) => {
  const { percent, isGreen } = props

  return (
    <S.Wrapper isgreen={isGreen}>
      {isGreen ? <SvgPriceUp /> : <SvgPriceDown />}
      {Number(Math.abs(percent)).toFixed(2)}%
    </S.Wrapper>
  )
}
