import { DefaultTheme } from 'styled-components'

export const lightTheme: DefaultTheme = {
  color: {
    bg: `#F5F5F5`,
    text: `#08090B`,
    white: '#FFFFFF',
    hint: `#A5A9AE`,
    link: `#007AFF`,
    btn: `#007AFF`,
    btnText: `#FFFFFF`,
    bgSecondary: `#EBEBEB`,
    orange: `#FBBC05`,
    green: `#62C56D`,
    red: `#E94646`,
    greenSuccess: '#62C56D',
    lightGrey: '#EBEBEB',
    iceGrey: '#A5A9AE',
    grey: `#717680`,
    greyDark: `#404247`,
    mainBlack: `#08090B`
  },
}

export const darkTheme: DefaultTheme = {
  color: {
    bg: `#141414`,
    text: `#FFF`,
    white: '#08090B',
    hint: `#A5A9AE`,
    link: `#007AFF`,
    btn: `#007AFF`,
    btnText: `#FFFFFF`,
    bgSecondary: `#242424`,
    orange: `#FBBC05`,
    green: `#62C56D`,
    red: `#E94646`,
    greenSuccess: '#62C56D',
    lightGrey: '#242424',
    iceGrey: '#848486',
    grey: `#AEAEAE`,
    greyDark: `#F0F0F0`,
    mainBlack: `#FFF`
  },
}
