import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgPlusBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={24} viewBox="0 0 25 24" width={24} xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1548 2H8.77484C5.13484 2 2.96484 4.17 2.96484 7.81V16.18C2.96484 19.83 5.13484 22 8.77484 22H17.1448C20.7848 22 22.9548 19.83 22.9548 16.19V7.81C22.9648 4.17 20.7948 2 17.1548 2Z" fill="#007AFF" opacity="0.2"/>
    <path d="M16.9648 11.25H13.7148V8C13.7148 7.59 13.3748 7.25 12.9648 7.25C12.5548 7.25 12.2148 7.59 12.2148 8V11.25H8.96484C8.55484 11.25 8.21484 11.59 8.21484 12C8.21484 12.41 8.55484 12.75 8.96484 12.75H12.2148V16C12.2148 16.41 12.5548 16.75 12.9648 16.75C13.3748 16.75 13.7148 16.41 13.7148 16V12.75H16.9648C17.3748 12.75 17.7148 12.41 17.7148 12C17.7148 11.59 17.3748 11.25 16.9648 11.25Z" fill="#007AFF"/>
  </svg>
)
const Memo = memo(SvgPlusBlue)
export default Memo
