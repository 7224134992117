import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.color.bg};
  min-height: 100vh;
  justify-content: space-between;
`