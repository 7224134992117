import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { BackButton } from 'features'
import * as S from './style'
import { isValidMnemonic } from 'utils/validateMnemonic'
import { updateMnemonic } from 'store/features/userSlice'
import { showError } from 'ui/Toast'

export const ImportWallet: FC = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setInputValue(text);
    } catch (error) {
      console.error('Failed to read clipboard contents: ', error);
    }
  }

  const handleSubmit = () => {
    if (!isValidMnemonic(inputValue)) {
      showError({ title: 'Please enter 12 or 24 word mnemonic phrase' });
      return;
    }
    dispatch(updateMnemonic(inputValue));
    navigate(AppRoutes.MyWallet);
  }

  return (
    <>
      <S.Wrapper>
        <BackButton />
        <S.MainBlock>
          <S.Title>
            Import wallet
          </S.Title>
          <S.InputBlockWrapper>
            <S.InputBlock
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              rows={5}
              placeholder='Enter the memonic phrase, private key, separate the mnemonic words by spaces'
            />
            <S.PasteButton onClick={handlePaste}>
              Paste
            </S.PasteButton>
          </S.InputBlockWrapper>
        </S.MainBlock>
        <S.BtnWrapper isDisabled={!inputValue} onClick={handleSubmit}>
          Import Wallet
        </S.BtnWrapper>
      </S.Wrapper>
    </>
  )
}
