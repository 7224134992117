import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { BackButton } from 'features'
import { updatePassword } from 'store/features/userSlice'
import { Input } from 'ui'
import { showError } from 'ui/Toast'
import { getPasswordIssue } from 'utils/password'
import * as S from './style'

export const CreateWalletPassword: FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!password) {
      showError({ title: 'Please enter password' });
      return;
    }
    const passwordIssue = getPasswordIssue(password);
    if (passwordIssue) {
      showError({ title: passwordIssue });
      return;
    }
    if (password !== confirmPassword) {
      showError({ title: 'Passwords are not equal' });
      return;
    }
    dispatch(updatePassword(password));
    navigate(AppRoutes.CreateWalletWarning);
  };

  return (
    <>
      <BackButton />
      <S.MainBlock>
        <S.Title>
          Create security password
        </S.Title>
        <S.Info>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos doloribus beatae, architecto fuga nemo id deleniti odit ullam similique rem, magni totam facere possimus nobis corrupti cupiditate ad quis maxime!
        </S.Info>
        <Input
          isSearchInput={false}
          label='Set password'
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Max 6 characters"
          type="password"
        />
        <Input
          isSearchInput={false}
          label='Confirm password'
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Max 6 characters"
          type='password'
        />
      </S.MainBlock>
      <S.BtnWrapper onClick={handleSubmit}>
        Set Password
      </S.BtnWrapper>
    </>
  )
}
