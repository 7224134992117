import styled from 'styled-components'
import { Button } from 'ui'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.color.bg};
  min-height: 100vh;
  justify-content: space-between;
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.color.text};
`

export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const InputBlockWrapper = styled.div`
  position: relative;
`

export const PasteButton = styled.button`
  border: 0;
  background-color: transparent;
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: ${({ theme }) => theme.color.btn};
`

export const InputBlock = styled.textarea`
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  background: ${({ theme }) => theme.color.white};
  padding: 16px;
  line-height: 140%;
`

export const BtnWrapper = styled(Button)`
  width: 100%;
  margin-top: 24px;
  background: ${({ theme }) => theme.color.btn};
`