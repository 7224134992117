import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { TelegramProvider } from 'providers/TelegramProvider'
import { store } from './store';
import { ThemeProvider } from 'providers/ThemeProvider';
import { ToastContainer } from 'ui/ToastContainer';
import { QueryClient, QueryClientProvider } from 'react-query'
import { AxiosError } from 'axios';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => {
        if (err instanceof AxiosError) {
          console.log(`Ooops! ${err.name}: ${err.message}`)
        }
      },
    },
    mutations: {
      onError: (err) => {
        if (err instanceof AxiosError) {
          console.log(`Ooops! ${err.name}: ${err.message}`)
        }
      },
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <TelegramProvider>
          <ThemeProvider>
            <App />
            <ToastContainer />
          </ThemeProvider>
        </TelegramProvider>
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
  // </React.StrictMode>
);
