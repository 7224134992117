import { FC } from 'react'
import { walletBanner } from 'assets/banners/big'
import { ArrowButton } from 'ui/ArrowButton'

import { SvgImport, SvgPlusBlue, SvgWalletBlue } from 'ui/icons'
import * as S from './style'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'

interface NotAuthorizedProps {
  onCreateNewClick: VoidFunction
}

export const NotAuthorized: FC<NotAuthorizedProps> = ({ onCreateNewClick }) => {
  const navigate = useNavigate();

  const importWallet = () => {
    navigate(AppRoutes.ImportWallet);
  }

  const handleConnectWalletBtnClick = () => {
  }

  return (
    <S.Wrapper>
      {/* <S.LogoWrapper/> */}
      <S.BannerImage src={walletBanner} />
      {/* <S.Title>
        Your digital wallet,
        <br /> now on <S.Biggest>TG20.</S.Biggest>
      </S.Title> */}
      <S.Info>
        Wallet options
      </S.Info>
      <S.ButtonsWrapper>
        <ArrowButton Icon={SvgPlusBlue} onClick={() => onCreateNewClick()}>
          Set Up new wallet
        </ArrowButton>
        <ArrowButton Icon={SvgImport} onClick={importWallet}>
          Import existing wallet
        </ArrowButton>
        <S.Info>
          Or
        </S.Info>
        <ArrowButton Icon={SvgWalletBlue} onClick={handleConnectWalletBtnClick}>
          Connect Wallet
        </ArrowButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  )
}
