import { TopToken } from 'api/types'
import { SvgGramIcon, SvgVerify } from 'ui/icons'
import { formatNumberWithSeparators } from 'utils/formNumberWithSeparators'
import { PriceChange } from 'ui'
import * as S from './style'

type TokenProps = {
  token: TopToken
}

export const Token = ({ token }: TokenProps) => {

  const priceIncreased = Number(token.change_24h) > 0;

  return (
    <S.Box>
      <S.Logo>
        {token.image_url ? (
          <S.Img alt="token_logo" src={token.image_url} />
        ) : (
          <SvgGramIcon style={{ width: '30px', height: '30px' }} />
        )}
        <S.Wrapper>
          <S.TokenTitleWrapper>
            <S.TokenTitle children={token.tick.toUpperCase()} />
            {token.verified && <SvgVerify />}
          </S.TokenTitleWrapper>
          <S.TokenDescription
            children={`${token.tick[0].toUpperCase() + token.tick.slice(1)
              } Token`}
          />
        </S.Wrapper>
      </S.Logo>
      <S.Info>
        <S.Volume
          children={`$${formatNumberWithSeparators(
            Number(token.price)
          )}`}
        />
        <PriceChange percent={token.change_24h} isGreen={priceIncreased} />
      </S.Info>

    </S.Box>
  )
}
