import { useState, useCallback, useMemo } from 'react'
import { Container } from 'ui'
import { SvgVerified } from 'ui/icons'
import type { Tab } from 'ui/Tabs/Tabs'
import { Header, Tokens } from './components'
import * as S from './style'
import { getSearchedTokensList, getTopTokensList } from 'api'
import { useQuery } from 'react-query'
import { Stats } from './components/Stats/Stats'
import { useDebounce } from 'hooks/useDebounce/useDebounce'

export const Home = () => {
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const [searchedValue, setSearchedValue] = useState('')
  const debauncedSearchValue = useDebounce(searchedValue)

  const { data: topTokens, isLoading: isTopTokensLoading } = useQuery(
    ['topTokens', currentTab.value],
    () => getTopTokensList()
  )

  const { data: searchedTokens, isLoading: isSearchedTokensLoading } = useQuery(
    ['searchedTokens', debauncedSearchValue],
    () => getSearchedTokensList({ query: debauncedSearchValue.toLowerCase() })
  )


  const updateSearchedValue = useCallback((value: string) => {
    setSearchedValue(value)
  }, [])

  const isLoading = useMemo(
    () => isSearchedTokensLoading || isTopTokensLoading,
    [isSearchedTokensLoading, isTopTokensLoading]
  )

  return (
    <S.Home>
      <Header
        searchValue={searchedValue}
        updateSearchValue={updateSearchedValue}
      />
      {(!debauncedSearchValue &&
        <>
          <Stats />
          <Container>
            <S.TabsWrapper
              containerClassName="tabs"
              onChange={setCurrentTab}
              selectedTab={currentTab}
              tabs={tabs}
            />
          </Container>
        </>
      )}

      <Tokens
        loading={isLoading}
        tokens={debauncedSearchValue ? searchedTokens : topTokens}
      />
    </S.Home>
  )
}

const tabs: Tab[] = [
  {
    label: 'Top',
    value: 'all',
  },
  {
    label: 'Verified',
    value: 'verified',
    icon: <SvgVerified />,
  },
  {
    label: 'Hot',
    value: 'hot',
  },
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'Gainers',
    value: 'gainers',
  }
]
