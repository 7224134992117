import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom';
import { CreateWalletConfirm } from './CreateWalletConfirm/CreateWalletConfirm';
import { CreateWalletMnemonic } from './CreateWalletMnemonic/CreateWalletMnemonic';
import { CreateWalletPassword } from './CreateWalletPassword/CreateWalletPassword';
import { CreateWalletWarning } from './CreateWalletWarning/CreateWalletWarning';

import * as S from './style'

export const CreateWallet: FC = () => {
  return (
    <>
      <S.Wrapper>
        <Routes>
          <Route Component={CreateWalletPassword} path={`password`} />
          <Route Component={CreateWalletWarning} path={`warning`} />
          <Route Component={CreateWalletMnemonic} path={`mnemonic`} />
          <Route Component={CreateWalletConfirm} path={`confirm`} />
        </Routes>
      </S.Wrapper>
    </>
  )
}
