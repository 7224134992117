import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'assets/style/theme';
import { GlobalStyle } from 'assets/style/GlobalStyle';
import { FCWithChildren } from 'types/app';
import { useTelegram } from 'hooks/useTelegram/useTelegram';

export const ThemeProvider: FCWithChildren = (props) => {
    const { children } = props
    const { theme } = useTelegram();
    console.log(theme);


    return (
        <StyledThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
            <GlobalStyle />
            {children}
        </StyledThemeProvider>
    );
};