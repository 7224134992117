import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgWalletBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={24} viewBox="0 0 25 24" width={25} xmlns="http://www.w3.org/2000/svg">
  <path d="M18.4448 10.55C18.0248 10.96 17.7848 11.55 17.8448 12.18C17.9348 13.26 18.9248 14.05 20.0048 14.05H21.9648V15.5C21.9648 18.5 19.9648 20.5 16.9648 20.5H7.96484C4.96484 20.5 2.96484 18.5 2.96484 15.5V8.5C2.96484 5.78 4.60485 3.88 7.15485 3.56C7.41485 3.52 7.68484 3.5 7.96484 3.5H16.9648C17.2248 3.5 17.4748 3.50999 17.7148 3.54999C20.2948 3.84999 21.9648 5.76 21.9648 8.5V9.95001H19.8848C19.3248 9.95001 18.8148 10.17 18.4448 10.55Z" fill="#007AFF" opacity="0.2"/>
  <path d="M22.9651 10.9699V13.03C22.9651 13.58 22.5251 14.0299 21.9651 14.0499H20.005C18.925 14.0499 17.9351 13.2599 17.8451 12.1799C17.7851 11.5499 18.025 10.9599 18.445 10.5499C18.815 10.1699 19.325 9.94995 19.885 9.94995H21.9651C22.5251 9.96995 22.9651 10.4199 22.9651 10.9699Z" fill="#007AFF"/>
</svg>
)
const Memo = memo(SvgWalletBlue)
export default Memo
