import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.19366 10.1133C7.06033 10.1133 6.93366 10.06 6.84033 9.96667L5.22703 8.35333C5.0337 8.16 5.0337 7.84 5.22703 7.64667C5.42036 7.45333 5.74036 7.45333 5.9337 7.64667L7.19366 8.90667L10.0603 6.04002C10.2537 5.84669 10.5737 5.84669 10.767 6.04002C10.9603 6.23335 10.9603 6.55335 10.767 6.74667L7.547 9.96667C7.45367 10.06 7.327 10.1133 7.19366 10.1133Z"
      fill="#A5A9AE"
    />
    <path
      d="M8.00027 15.1668C7.58027 15.1668 7.16027 15.0268 6.83361 14.7468L5.78025 13.8402C5.67358 13.7468 5.40691 13.6535 5.26691 13.6535H4.12025C3.13358 13.6535 2.33358 12.8535 2.33358 11.8668V10.7268C2.33358 10.5868 2.24025 10.3268 2.14691 10.2202L1.24691 9.16016C0.700247 8.5135 0.700247 7.4935 1.24691 6.84683L2.14691 5.78683C2.24025 5.68016 2.33358 5.42016 2.33358 5.28016V4.1335C2.33358 3.14683 3.13358 2.34683 4.12025 2.34683H5.27358C5.41358 2.34683 5.68025 2.24683 5.78691 2.16016L6.84027 1.2535C7.49361 0.693496 8.51361 0.693496 9.16694 1.2535L10.2203 2.16016C10.3269 2.2535 10.5936 2.34683 10.7336 2.34683H11.8669C12.8536 2.34683 13.6536 3.14683 13.6536 4.1335V5.26683C13.6536 5.40683 13.7536 5.6735 13.8469 5.78016L14.7536 6.8335C15.3136 7.48683 15.3136 8.50683 14.7536 9.16016L13.8469 10.2135C13.7536 10.3202 13.6536 10.5868 13.6536 10.7268V11.8602C13.6536 12.8468 12.8536 13.6468 11.8669 13.6468H10.7336C10.5936 13.6468 10.3269 13.7468 10.2203 13.8335L9.16694 14.7402C8.84027 15.0268 8.42027 15.1668 8.00027 15.1668ZM4.12025 3.34683C3.68691 3.34683 3.33358 3.70016 3.33358 4.1335V5.2735C3.33358 5.6535 3.15358 6.14016 2.90691 6.42683L2.00691 7.48683C1.77358 7.76016 1.77358 8.2335 2.00691 8.50683L2.90691 9.56683C3.15358 9.86016 3.33358 10.3402 3.33358 10.7202V11.8602C3.33358 12.2935 3.68691 12.6468 4.12025 12.6468H5.27358C5.66025 12.6468 6.14691 12.8268 6.44025 13.0802L7.49361 13.9868C7.76694 14.2202 8.24694 14.2202 8.52027 13.9868L9.57361 13.0802C9.86694 12.8335 10.3536 12.6468 10.7403 12.6468H11.8736C12.3069 12.6468 12.6603 12.2935 12.6603 11.8602V10.7268C12.6603 10.3402 12.8403 9.8535 13.0936 9.56016L14.0003 8.50683C14.2336 8.2335 14.2336 7.7535 14.0003 7.48016L13.0936 6.42683C12.8403 6.1335 12.6603 5.64683 12.6603 5.26016V4.1335C12.6603 3.70016 12.3069 3.34683 11.8736 3.34683H10.7403C10.3536 3.34683 9.86694 3.16683 9.57361 2.9135L8.52027 2.00683C8.24694 1.7735 7.76694 1.7735 7.49361 2.00683L6.44025 2.92016C6.14691 3.16683 5.65358 3.34683 5.27358 3.34683H4.12025Z"
      fill="#A5A9AE"
    />
  </svg>
)
const Memo = memo(SvgVerified)
export default Memo
