import styled from 'styled-components'

export const Wrapper = styled.button<{
  disabled?: boolean
}>`
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 22px;
  transition: 0.3s;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  opacity: ${({ disabled }) => disabled && 0.5};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.color.white};
  svg:last-child {
    margin-left: auto;
  }
`
