import styled from 'styled-components'
import { Button } from 'ui'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`

// export const TopBlock = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   padding-top: 24px;
//   margin-bottom: 40px;
// `

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.color.text};
`

export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  // background-color: ${({ theme }) => theme.color.bg};
`

export const Info = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: ${({ theme }) => theme.color.hint};
  margin-bottom: 40px;
`

export const BtnWrapper = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.color.btn};
`