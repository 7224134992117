import { FC, useMemo, useState } from 'react'
import * as bip39 from 'bip39';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { updateMnemonic, updateIsWalletBackedUp } from 'store/features/userSlice';
import { RootState } from 'store/reducers';
import { Container } from 'ui/Container/Container'
import {
  SvgArrowSwap,
  SvgIconWalletTg,
} from 'ui/icons'
import { type Tab } from 'ui/TabsFilled/TabsFilled'
// import { MyTransactions } from './components/MyTransfers/MyTransfers'
import { NotAuthorized } from './components/NotAuthorized/NotAuthorized'
import * as S from './style'
import { BackupAlertPopup } from 'ui/BackupAlertPopup/BackupAlertPopup'

const tabs: Tab[] = [
  {
    label: 'Assets',
    value: 'assets',
  },
  {
    label: 'Transactions',
    value: 'transactions',
  },
]

export const MyWallet: FC = () => {
  const [currentTab, setCurrentTab] = useState(tabs[0])

  const dispatch = useDispatch()

  const currentWalletBalance = 0;

  const navigate = useNavigate()

  const isWalletBackedUp = useSelector((state: RootState) => state.user.isWalletBackedUp);
  const mnemonic = useSelector((state: RootState) => state.user.mnemonic);

  const createNewWallet = () => {
    const newMnemonic = bip39.generateMnemonic(256);
    dispatch(updateMnemonic(newMnemonic))
    dispatch(updateIsWalletBackedUp(false))
  }


  const currentWalletContent = useMemo(() => {
    switch (currentTab.value) {
      // case 'assets':
      //   return <MyAssets />
      case 'transactions':
        // return <MyTransactions />
        return <div></div>
    }
  }, [currentTab.value])


  if (!mnemonic) {
    return <NotAuthorized onCreateNewClick={createNewWallet} />
  }

  return (
    <S.Wrapper>
      <S.TopWrapperBlock>
        <S.BalanceBlock>
          <S.BalanceBlockInner>
            <S.TotlaBalance>Total Balance</S.TotlaBalance>
            <S.Balance>
              {/* ${totalBalance ? totalBalance.toFixed(4) : '0.00'} */}
            </S.Balance>
            <S.InfoChange>
              {/* <S.Time>24h change</S.Time> */}
              {/* <S.Procent>{PROCENT_MOCK.procent}%</S.Procent> */}
            </S.InfoChange>
            <S.SvgRightDown>
              <SvgIconWalletTg />
            </S.SvgRightDown>
          </S.BalanceBlockInner>
        </S.BalanceBlock>
      </S.TopWrapperBlock>
      <S.WalletFunctions>
        {/* <S.SendBlockWrapper onClick={() => alert('Send button')}>
          <S.SendButton>
            <SvgSendSquare />
          </S.SendButton>
          <S.SendText>Send</S.SendText>
        </S.SendBlockWrapper> */}
        {/* <S.RecieveBlockWrapper onClick={toggleRecieveModal}>
          <S.RecieveButton>
            <SvgRecieveSquare />
          </S.RecieveButton>
          <S.RecieveText>Recieve</S.RecieveText>
        </S.RecieveBlockWrapper> */}
        {typeof currentWalletBalance === 'number' &&
          currentWalletBalance > 0 && (
            <S.SwapBlockWrapper onClick={() => navigate(AppRoutes.Swap)}>
              <S.SwapButton>
                <SvgArrowSwap />
              </S.SwapButton>
              <S.SwapText>Swap</S.SwapText>
            </S.SwapBlockWrapper>
          )}
      </S.WalletFunctions>
      <S.Line />

      {/* <PromoSlider slides={promoSlides} /> */}

      <S.TabsBlock>
        <Container>
          <S.Tabs
            containerClassName="tabs"
            onChange={setCurrentTab}
            selectedTab={currentTab}
            tabs={tabs}
          />
        </Container>

        {currentWalletContent}
      </S.TabsBlock>

      {/* {isReceiveModalOpen && <ReceivePopup onClose={toggleRecieveModal} />} */}
      {!isWalletBackedUp && <BackupAlertPopup onClose={() => { }} />}

      {/* <div onClick={() => navigate(AppRoutes.TranferHistory)}>
        TransferHistory
      </div> */}
    </S.Wrapper>
  )
}
