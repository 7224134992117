export const BASE_API_URL = process.env.REACT_APP_API_URL

export const TON_CLIENT_URL = 'https://toncenter-v4.gram20.com/jsonRPC'

export const HUOBI_API_URL = 'https://api.huobi.pro'

export const ApiRoutes = {
  Users: 'users',
  TopTokensList: '/tokens',
  Balance: '/balance',
  TokenInfo: '/tick',
  TokenHolders: '/holders',
  TransfersHistory: '/history',
  CheckHash: '/check',
  HuobiMarketTonPrice: '/market/trade?symbol=tonusdt',
  MarketplaceListing: '/marketplace',
  MarketplaceStats: '/stats/marketplace',
  SearchTokens: '/tokens/search',
  MaketplaceTicks: '/marketplace_ticks',
  ImageValidate: '/image',
  SearchedOrder: '/order',
} as const
