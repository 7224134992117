import { ImportWallet } from "pages/ImportWallet/ImportWallet"

export const tonAddressExplorerLink = 'https://testnet.ton.cx/address/'

export const AppRoutes = {
  Home: '/',
  Swap: '/swap',
  Notifications: '/notifications',

  MyWallet: '/my-wallet',
  TransferHistory: '/my-wallet/transfer-history/:tick',
  TransferDetailed: '/my-wallet/transfer-detailed/:hash',

  ImportWallet: '/import-wallet',

  CreateWallet: '/create-wallet/*',
  CreateWalletPassword: '/create-wallet/password',
  CreateWalletWarning: '/create-wallet/warning',
  CreateWalletMnemonic: '/create-wallet/mnemonic',
  CreateWalletConfirm: '/create-wallet/confirm',
} as const

export const getVerifiedLink =
  'https://docs.tg20.com/introduction/get-your-project-verified'
