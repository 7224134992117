import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { BackButton } from 'features'
import { RootState } from 'store/reducers'
import * as S from './style'

export const CreateWalletMnemonic: FC = () => {
  const [revealed, setRevealed] = useState<boolean>(false)
  const navigate = useNavigate();

  const mnemonic = useSelector((state: RootState) => state.user.mnemonic);

  const handleSubmit = async () => {
    navigate(AppRoutes.CreateWalletConfirm);
  };

  const words = mnemonic.split(' ');

  return (
    <>
      <BackButton />
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="0">
        <defs>
          <filter id="blur-filter">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.5" />
          </filter>
        </defs>
      </svg>
      <S.MainBlock>
        <S.Title>
          Mnemonic Phrase
        </S.Title>
        <S.Subtitle>
          Please do not take screenshots or photos
        </S.Subtitle>
        <S.Desc>
          Your secret mnemonic phrase makes it easy to restore your account.
          Please manually copy the phrase in order to ensure accuracy.
        </S.Desc>
        <S.Desc>
          <S.Warning>Warning:</S.Warning> Never disclose your mnemonic phrase.
          Anyone with this phrase can take your account forever!
          Do not loose this Phrase!
        </S.Desc>
        <S.PhraseBlock>
          <S.PhraseColumn blur={!revealed}>
            {words.slice(0, 12)?.map((word: string, i: number) => (
              <S.PhraseWord>
                <S.PhraseNum>{i + 1}.</S.PhraseNum>{word}
              </S.PhraseWord>
            ))}
          </S.PhraseColumn>
          <S.PhraseColumn blur={!revealed}>
            {words.slice(12, 24)?.map((word: string, i: number) => (
              <S.PhraseWord>
                <S.PhraseNum>{12 + i + 1}.</S.PhraseNum>{word}
              </S.PhraseWord>
            ))}
          </S.PhraseColumn>
          {!revealed && <S.PhraseBlockBlur>
            <S.RevealButton onClick={() => { setRevealed(true) }}>
              Click here to reveal phrase
            </S.RevealButton>
          </S.PhraseBlockBlur>}
        </S.PhraseBlock>
      </S.MainBlock>
      <S.BtnWrapper isDisabled={!revealed} onClick={handleSubmit}>
        I wrote it down
      </S.BtnWrapper>
    </>
  )
}
