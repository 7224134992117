import { styled } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
`

export const RadioButton = styled.button<{
  selected?: boolean;
}>`
  border-radius: 6px;
  font-weight: 600;
  padding: 10px;
  border: 2px solid ${({ theme, selected }) => selected ? theme.color.btn : 'transparent'};
  background: ${({ theme }) => theme.color.lightGrey};
  color: ${({ theme, selected }) => selected ? theme.color.btn : theme.color.grey};
`
