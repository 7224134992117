import styled from 'styled-components'
import { Button } from 'ui'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.color.text};
`

export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  // background-color: ${({ theme }) => theme.color.bg};
`

export const LogoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
`

export const List = styled.ul`
  font-size: 14px;
  line-height: 140%;
`
export const ListItem = styled.li`
  margin-bottom: 16px;
`

export const BottomBlock = styled.div`
  width: 100%;
`

export const BtnWrapper = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.color.btn};
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
`

export const Checkbox = styled.input`
  cursor: pointer;
`

export const CheckboxLabel = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.color.text};
`