import styled from 'styled-components'
import { Button } from 'ui'

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.color.text};
`

export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Subtitle = styled.p`
  margin-bottom: 8px;
`

export const Desc = styled.p`
  color: ${({ theme }) => theme.color.grey};
  margin-bottom: 24px;
`

export const PhraseBlock = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.iceGrey};
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 56px;
  position: relative;
  overflow: hidden;
`

export const PhraseColumn = styled.div<{
  blur?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  filter: ${({ blur }) => blur ? 'url(#blur-filter)' : ''}};
`

export const PhraseWord = styled.div`
`

export const PhraseNum = styled.span`
  margin-right: 10px;
  color: ${({ theme }) => theme.color.iceGrey};
`

export const Warning = styled.span`
  color: ${({ theme }) => theme.color.red};
  font-weight: 700;
`

export const PhraseBlockBlur = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RevealButton = styled(Button)`
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.text};
`

export const BtnWrapper = styled(Button)`
  width: 100%;
  margin-top: 24px;
  background: ${({ theme }) => theme.color.btn};
`