import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgGramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={44}
    viewBox="0 0 44 44"
    width={44}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_109_1416)">
      <path
        d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z"
        fill="#0088CC"
      />
      <path
        d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z"
        fill="url(#paint0_radial_109_1416)"
      />
      <path
        clipRule="evenodd"
        d="M7.83789 21.9856C7.91091 10.0885 23.5996 5.93472 31.7524 12.8063C37.6306 17.4253 37.6306 26.5496 31.767 31.1758C23.6069 38.084 7.89631 33.901 7.83789 21.9856ZM20.3136 10.8394C16.0272 12.1617 13.121 16.8099 13.2488 21.9856C13.1356 27.1503 16.0382 31.7802 20.3136 33.1025C20.5759 33.1832 20.8535 33.2011 21.124 33.1548C21.3945 33.1084 21.6504 32.9991 21.8712 32.8357C22.092 32.6722 22.2715 32.4591 22.3954 32.2135C22.5193 31.9679 22.5841 31.6965 22.5846 31.4212V26.2382C23.7088 26.2382 24.787 25.7902 25.5819 24.9926C26.3769 24.1951 26.8235 23.1134 26.8235 21.9856C26.8235 20.8577 26.3769 19.776 25.5819 18.9785C24.787 18.181 23.7088 17.7329 22.5846 17.7329V12.5206C22.5841 12.2453 22.5193 11.974 22.3954 11.7284C22.2715 11.4827 22.092 11.2696 21.8712 11.1062C21.6504 10.9427 21.3945 10.8334 21.124 10.7871C20.8535 10.7408 20.5759 10.7587 20.3136 10.8394ZM18.6457 23.6175C18.4302 23.1005 18.3183 22.546 18.3164 21.9856C18.3183 21.4252 18.4302 20.8707 18.6457 20.3537C18.8613 19.8367 19.1762 19.3673 19.5725 18.9724C19.9688 18.5775 20.4388 18.2648 20.9556 18.0522C21.4724 17.8395 22.0259 17.731 22.5845 17.733V26.2382C22.0259 26.2401 21.4724 26.1317 20.9556 25.919C20.4388 25.7063 19.9688 25.3936 19.5725 24.9987C19.1762 24.6038 18.8613 24.1345 18.6457 23.6175Z"
        fill="white"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="translate(44 44) rotate(-135) scale(62.2254)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_109_1416"
        r={1}
      >
        <stop stopColor="#0098EA" />
        <stop offset={1} stopColor="#0057FF" />
      </radialGradient>
      <clipPath id="clip0_109_1416">
        <rect fill="white" height={44} width={44} />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgGramIcon)
export default Memo
