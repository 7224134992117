export function formatBigNumber(input: string): string {
    const num = parseInt(input.replace(/\s+/g, ''), 10);

    if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
}
