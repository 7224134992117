import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  password: string;
  mnemonic: string;
  isWalletBackedUp: boolean;
}

const initialState: UserState = {
  password: '',
  mnemonic: '',
  isWalletBackedUp: true
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updatePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    updateMnemonic: (state, action: PayloadAction<string>) => {
      state.mnemonic = action.payload;
    },
    updateIsWalletBackedUp: (state, action: PayloadAction<boolean>) => {
      state.isWalletBackedUp = action.payload;
    },
  },
});

export const { updatePassword, updateMnemonic, updateIsWalletBackedUp } = userSlice.actions;
export default userSlice.reducer;