import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { Modal } from 'ui'
import { SvgInfoIcon } from 'ui/icons'
import * as S from './style'

type ReceivePopupProps = {
  onClose: () => void
}

export const BackupAlertPopup: FC<ReceivePopupProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const navigateToCreatePass = () => {
    navigate(AppRoutes.CreateWalletPassword);
  }

  return (
    <Modal onClose={onClose}>
      <S.WrapperModal>
        <SvgInfoIcon/>
        <S.Label>The wallet has not been backed up yet!</S.Label>
        <S.Info>To avoid loosing access to the wallet permanently, please make sure to complete the wallet backup.</S.Info>
        <S.BtnWrapper onClick={navigateToCreatePass}>Backup Now!</S.BtnWrapper>
      </S.WrapperModal>
    </Modal>
  )
}
