import { FC } from 'react'
import { useQuery } from 'react-query'
import { getTopToken } from 'api'
import { formatBigNumber } from 'utils/formatBigNumber'
import * as S from './style'
import { PriceChange } from 'ui'

type StatsBlockProps = {}

export const Stats: FC<StatsBlockProps> = (props) => {
  const { data: marketplaceGramStats } = useQuery(
    ['makretplaceGramStatsData'],
    () => getTopToken({ tick: 'TGRAM' })
  )

  const gramFloorPrice = Number(marketplaceGramStats?.price || 0);
  const totalVolume = marketplaceGramStats?.market_cap;
  const volume24h = marketplaceGramStats?.volume_24h;
  const change24h = marketplaceGramStats?.change_24h;
  const priceIncreased = Number(marketplaceGramStats?.change_24h) > 0;

  return (
    <S.Wrapper>
      <S.StatItem>
        {!!(gramFloorPrice) ? (
          <S.Price children={`$${(gramFloorPrice).toFixed(6)}`} />
        ) : <S.SkeletonPrice containerClassName="skeleton-container" height={16} />}
        <S.Head>
          <S.StatText children="TGRAM Price" />
        </S.Head>
      </S.StatItem>
      <S.StatItem>
        {!!(volume24h) ? (
          <S.Price>
            {formatBigNumber(volume24h)}
            <PriceChange percent={Number(change24h)} isGreen={priceIncreased} />
          </S.Price>
        ) : <S.SkeletonPrice containerClassName="skeleton-container" height={16} />}
        <S.Head>
          <S.StatText children="24h Volume" />
        </S.Head>
      </S.StatItem>
      <S.StatItem>
        {!!(totalVolume) ? (
          <S.Price children={` $${formatBigNumber(totalVolume)}`} />
        ) : <S.SkeletonPrice containerClassName="skeleton-container" height={16} />}
        <S.Head>
          <S.StatText children="Total Volume" />
        </S.Head>
      </S.StatItem>
    </S.Wrapper>
  )
}
