import * as React from 'react'
import { SVGProps, memo } from 'react'
import { useTheme } from 'styled-components';
const HeaderLogo = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  console.log(theme);

  return (
    <svg width="83" height="20" viewBox="0 0 83 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.5762 9.77173H25.2403V12.1948H30.0192C30.0228 13.119 29.8005 14.03 29.3719 14.8476C28.9569 15.6205 28.3259 16.2543 27.5568 16.6707C26.7091 17.1155 25.7625 17.3352 24.8067 17.309C23.7351 17.325 22.6816 17.0296 21.773 16.458C20.8492 15.8532 20.1201 14.9914 19.6745 13.9775C19.1653 12.8869 18.9101 11.553 18.9087 9.97596C18.9072 8.39888 19.1597 7.0707 19.666 5.99142C20.1089 4.98152 20.8306 4.12061 21.7455 3.51092C22.6248 2.94385 23.65 2.64804 24.6945 2.65998C25.2929 2.65362 25.8886 2.73971 26.461 2.91526C26.9839 3.07712 27.4739 3.33142 27.908 3.66622C28.3333 3.99464 28.7002 4.39313 28.9932 4.84477C29.3128 5.32301 29.5615 5.84542 29.7315 6.39562H32.7165C32.4077 4.57617 31.442 2.93536 30.0044 1.78776C29.281 1.20816 28.4618 0.76099 27.5843 0.466675C26.6436 0.149671 25.657 -0.00777423 24.6649 0.000784282C23.4731 -0.0126191 22.2916 0.223562 21.1955 0.694302C20.146 1.1476 19.2075 1.82569 18.4454 2.68125C17.6384 3.59686 17.0239 4.66709 16.6387 5.82761C16.1959 7.17545 15.9813 8.58854 16.0041 10.0079C16.0041 12.0629 16.3722 13.8357 17.1084 15.3263C17.784 16.75 18.8515 17.949 20.1843 18.7811C21.5814 19.6139 23.183 20.0355 24.8067 19.9979C26.2525 20.0263 27.6824 19.6899 28.9657 19.0194C30.1572 18.3854 31.1437 17.4221 31.809 16.2432C32.5 15.0391 32.8455 13.6201 32.8455 11.9863V11.0609C32.8456 10.7212 32.7122 10.3952 32.4746 10.1538C32.2369 9.9124 31.914 9.7751 31.5762 9.77173Z" fill={theme.color.text} />
      <path d="M0.918945 2.79818V0.266846H16.0023V2.79818H9.92137V19.7562H6.99035V2.79818H0.918945Z" fill={theme.color.text} />
      <g>
        <path d="M82.6195 10.5707V11.7704H77.8447V10.5707H82.6195ZM79.2364 8.36328H80.652V17.1451C80.652 17.545 80.71 17.8449 80.826 18.0448C80.9459 18.2408 81.0979 18.3728 81.2819 18.4407C81.4698 18.5047 81.6678 18.5367 81.8757 18.5367C82.0317 18.5367 82.1596 18.5287 82.2596 18.5127C82.3596 18.4927 82.4396 18.4767 82.4996 18.4647L82.7875 19.7364C82.6915 19.7724 82.5575 19.8084 82.3856 19.8444C82.2136 19.8844 81.9957 19.9044 81.7317 19.9044C81.3318 19.9044 80.9399 19.8184 80.556 19.6464C80.1761 19.4745 79.8602 19.2126 79.6083 18.8606C79.3603 18.5087 79.2364 18.0648 79.2364 17.529V8.36328Z" fill={theme.color.greyDark} />
      </g>
      <g>
        <path d="M72.644 19.9765C71.7563 19.9765 70.9905 19.7806 70.3466 19.3887C69.7068 18.9928 69.2129 18.4409 68.865 17.7331C68.5211 17.0213 68.3491 16.1935 68.3491 15.2497C68.3491 14.306 68.5211 13.4742 68.865 12.7543C69.2129 12.0305 69.6968 11.4667 70.3166 11.0628C70.9405 10.6549 71.6683 10.4509 72.5001 10.4509C72.98 10.4509 73.4538 10.5309 73.9217 10.6909C74.3896 10.8508 74.8155 11.1108 75.1994 11.4707C75.5833 11.8266 75.8892 12.2985 76.1172 12.8863C76.3451 13.4742 76.4591 14.198 76.4591 15.0578V15.6576H69.3569V14.4339H75.0194C75.0194 13.9141 74.9155 13.4502 74.7075 13.0423C74.5036 12.6344 74.2116 12.3125 73.8317 12.0765C73.4558 11.8406 73.0119 11.7226 72.5001 11.7226C71.9362 11.7226 71.4483 11.8626 71.0364 12.1425C70.6285 12.4184 70.3146 12.7783 70.0947 13.2222C69.8747 13.6661 69.7648 14.142 69.7648 14.6499V15.4657C69.7648 16.1615 69.8847 16.7513 70.1247 17.2352C70.3686 17.7151 70.7065 18.081 71.1384 18.3329C71.5703 18.5809 72.0722 18.7049 72.644 18.7049C73.0159 18.7049 73.3519 18.6529 73.6518 18.5489C73.9557 18.4409 74.2176 18.281 74.4376 18.069C74.6575 17.8531 74.8275 17.5851 74.9475 17.2652L76.3151 17.6491C76.1712 18.113 75.9292 18.5209 75.5893 18.8728C75.2494 19.2207 74.8295 19.4927 74.3296 19.6886C73.8297 19.8806 73.2679 19.9765 72.644 19.9765Z" fill={theme.color.greyDark} />
      </g>
      <g>
        <path d="M66.1881 7.49951V19.7844H64.7725V7.49951H66.1881Z" fill={theme.color.greyDark} />
      </g>
      <g>
        <path d="M62.1798 7.49951V19.7844H60.7642V7.49951H62.1798Z" fill={theme.color.greyDark} />
      </g>
      <g>
        <path d="M53.9572 20.0005C53.3733 20.0005 52.8435 19.8906 52.3676 19.6706C51.8917 19.4467 51.5138 19.1247 51.2339 18.7049C50.9539 18.281 50.814 17.7691 50.814 17.1692C50.814 16.6414 50.9179 16.2135 51.1259 15.8856C51.3338 15.5536 51.6118 15.2937 51.9597 15.1058C52.3076 14.9178 52.6915 14.7778 53.1114 14.6859C53.5353 14.5899 53.9612 14.5139 54.3891 14.4579C54.9489 14.3859 55.4028 14.332 55.7507 14.296C56.1026 14.256 56.3586 14.19 56.5185 14.098C56.6825 14.006 56.7645 13.8461 56.7645 13.6181V13.5701C56.7645 12.9783 56.6025 12.5184 56.2786 12.1905C55.9587 11.8626 55.4728 11.6986 54.821 11.6986C54.1451 11.6986 53.6153 11.8466 53.2314 12.1425C52.8475 12.4384 52.5775 12.7543 52.4216 13.0903L51.0779 12.6104C51.3178 12.0505 51.6378 11.6146 52.0377 11.3027C52.4416 10.9868 52.8814 10.7668 53.3573 10.6429C53.8372 10.5149 54.3091 10.4509 54.773 10.4509C55.0689 10.4509 55.4088 10.4869 55.7927 10.5589C56.1806 10.6269 56.5545 10.7688 56.9144 10.9848C57.2783 11.2007 57.5803 11.5267 57.8202 11.9625C58.0601 12.3984 58.1801 12.9823 58.1801 13.7141V19.7846H56.7645V18.5369H56.6925C56.5965 18.7368 56.4366 18.9508 56.2126 19.1787C55.9887 19.4067 55.6907 19.6006 55.3188 19.7606C54.9469 19.9205 54.493 20.0005 53.9572 20.0005ZM54.1731 18.7288C54.733 18.7288 55.2049 18.6189 55.5888 18.3989C55.9767 18.179 56.2686 17.8951 56.4645 17.5471C56.6645 17.1992 56.7645 16.8333 56.7645 16.4494V15.1537C56.7045 15.2257 56.5725 15.2917 56.3686 15.3517C56.1686 15.4077 55.9367 15.4577 55.6727 15.5017C55.4128 15.5416 55.1589 15.5776 54.9109 15.6096C54.667 15.6376 54.469 15.6616 54.3171 15.6816C53.9492 15.7296 53.6053 15.8076 53.2853 15.9156C52.9694 16.0195 52.7135 16.1775 52.5175 16.3894C52.3256 16.5974 52.2296 16.8813 52.2296 17.2412C52.2296 17.7331 52.4116 18.105 52.7755 18.3569C53.1434 18.6049 53.6093 18.7288 54.1731 18.7288Z" fill={theme.color.greyDark} />
      </g>
      <g>
        <path d="M38.4929 19.7844L35.1338 7.49951H36.6454L39.2128 17.505H39.3327L41.9481 7.49951H43.6277L46.243 17.505H46.363L48.9303 7.49951H50.4419L47.0828 19.7844H45.5472L42.8359 9.99488H42.7399L40.0286 19.7844H38.4929Z" fill={theme.color.greyDark} />
      </g>
    </svg>

  )
}
const Memo = memo(HeaderLogo)
export default Memo
