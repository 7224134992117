import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgVerify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 19 16"
    width={19}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="fi_9195920">
      <g id="Layer 2">
        <path
          d="M14.3557 5.83667V4.13474C14.3557 3.75222 14.2037 3.38536 13.9332 3.11488C13.6627 2.84439 13.2959 2.69244 12.9134 2.69244H11.2475L10.0648 1.50974C9.79457 1.24111 9.42902 1.09033 9.04798 1.09033C8.66694 1.09033 8.30139 1.24111 8.03115 1.50974L6.88452 2.69244H5.1826C4.80007 2.69244 4.43322 2.84439 4.16273 3.11488C3.89225 3.38536 3.74029 3.75222 3.74029 4.13474V5.83667L2.5576 6.9833C2.28897 7.25354 2.13818 7.61909 2.13818 8.00013C2.13818 8.38117 2.28897 8.74672 2.5576 9.01696L3.74029 10.1996V11.8655C3.74029 12.248 3.89225 12.6149 4.16273 12.8854C4.43322 13.1559 4.80007 13.3078 5.1826 13.3078H6.88452L8.03115 14.4905C8.30139 14.7591 8.66694 14.9099 9.04798 14.9099C9.42902 14.9099 9.79457 14.7591 10.0648 14.4905L11.2475 13.3078H12.9134C13.2959 13.3078 13.6627 13.1559 13.9332 12.8854C14.2037 12.6149 14.3557 12.248 14.3557 11.8655V10.1996L15.5384 9.01696C15.807 8.74672 15.9578 8.38117 15.9578 8.00013C15.9578 7.61909 15.807 7.25354 15.5384 6.9833L14.3557 5.83667Z"
          fill="#2196F3"
          id="Vector"
        />
        <path
          d="M8.51152 9.70333L8.51153 9.7032L8.50647 9.70323C8.42472 9.7037 8.34368 9.68803 8.268 9.65712C8.19232 9.62621 8.12349 9.58066 8.06545 9.52309L8.0648 9.52246L6.95472 8.44121C6.83795 8.32421 6.77236 8.16565 6.77236 8.00034C6.77236 7.83482 6.83812 7.67607 6.95516 7.55903C7.0722 7.44199 7.23094 7.37624 7.39647 7.37624C7.56189 7.37624 7.72054 7.44191 7.83756 7.55881L8.40704 8.13551L8.47791 8.20727L8.54906 8.1358L10.2005 6.47715L10.2005 6.47717L10.2023 6.47534C10.3198 6.35115 10.4819 6.27875 10.6528 6.27407C10.8238 6.26939 10.9896 6.3328 11.1138 6.45037C11.238 6.56793 11.3104 6.73001 11.315 6.90095C11.3197 7.07146 11.2566 7.23685 11.1397 7.36093L8.97739 9.52319C8.97736 9.52322 8.97733 9.52325 8.9773 9.52328C8.91636 9.58387 8.84351 9.63115 8.76336 9.66215C8.68317 9.69315 8.59741 9.70718 8.51152 9.70333Z"
          fill="white"
          id="Vector_2"
          stroke="#0098EA"
          strokeWidth={0.2}
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgVerify)
export default Memo
