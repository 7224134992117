import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { BackButton } from 'features'
import { updateIsWalletBackedUp } from 'store/features/userSlice'
import { RootState } from 'store/reducers'
import RadioButtons from 'ui/RadioButtons/RadioButtons'
import * as S from './style'
import { showError } from 'ui/Toast'

export const CreateWalletConfirm: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mnemonic = useSelector((state: RootState) => state.user.mnemonic);
  const mnemonicArr = useMemo(() => mnemonic.split(' '), [mnemonic]);

  const [options, setOptions] = useState<string[][]>([]);
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [selectedWords] = useState<string[]>([]);

  useEffect(() => {
    const indices = Array.from({ length: mnemonicArr.length }, (_, i) => i);
    const shuffledIndices = indices.sort(() => 0.5 - Math.random()).slice(0, 4);
    const sortedIndices = shuffledIndices.sort((a, b) => a - b);
    setSelectedIndices(sortedIndices);

    const newOptions = shuffledIndices.map(index => {
      const word = mnemonicArr[index];
      const decoys = mnemonicArr.filter((_, i) => !shuffledIndices.includes(i)).sort(() => 0.5 - Math.random()).slice(0, 2);
      return [word, ...decoys].sort(() => 0.5 - Math.random());
    });

    setOptions(newOptions);
  }, [mnemonicArr]);

  const handleSelect = (index: number, word: string) => {
    selectedWords[index] = word;
  }

  const handleSubmit = () => {
    const isValid = selectedIndices.every((index) => selectedWords[index] === mnemonicArr[index]);
    if (isValid) {
      navigate(AppRoutes.MyWallet);
      dispatch(updateIsWalletBackedUp(true))
    } else {
      showError({ title: 'Wrong mnemonic' });
    }
  };


  return (
    <>
      <BackButton />
      <S.MainBlock>
        <S.Title>
          Verify Mnemonic Phrase
        </S.Title>
        <S.Desc>
          Please complete the verification code to ensure accurate recording.
        </S.Desc>
        <S.RadioButtonsBlock>
          {options.map((group, index) => (
            <S.RadioButtonsWrapper key={index}>
              <S.RadioButtonsIndex>{selectedIndices[index] + 1}.</S.RadioButtonsIndex>
              <RadioButtons onChange={(word) => handleSelect(selectedIndices[index], word)} options={group} />
            </S.RadioButtonsWrapper>
          ))}
        </S.RadioButtonsBlock>
      </S.MainBlock>
      <S.BtnWrapper onClick={(handleSubmit)}>
        Confirm
      </S.BtnWrapper>
    </>
  )
}
