import React, { createContext, useEffect, useState } from 'react';
import { registerUser } from 'api/user';
import { FCWithChildren } from 'types/app';

export type TelegramContextType = {
  theme?: string
}

export const TelegramContext = createContext<TelegramContextType>({})

export const TelegramProvider: FCWithChildren = (props) => {
  const { children } = props
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const app = window.Telegram?.WebApp
    console.log(app.colorScheme)

    if (app.initData) {
      app.ready()
      app.expand()
      // app.enableClosingConfirmation()
      setTheme(app.colorScheme);
      const initData = app.initData;
      registerUser(initData)
    }
  }, []);

  return (
    <TelegramContext.Provider value={{ theme }}>
      {children}
    </TelegramContext.Provider>
  );
};
