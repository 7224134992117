import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgIconWalletTg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={22}
    viewBox="0 0 21 22"
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3614 5.94606C11.7835 5.94606 13.0736 6.50432 14.0139 7.41258L17.6414 3.8303C15.7714 2.01552 13.1985 0.895508 10.3614 0.895508C4.69092 0.895508 0.0820777 5.36505 0 10.9091H5.14948C5.23156 8.15459 7.53331 5.94606 10.3614 5.94606Z"
      fill="white"
    />
    <path
      d="M10.2351 10.8367L12.8349 14.8793C12.8349 14.8793 16.0377 14.3052 19.6134 11.7642C14.5353 16.414 11.2522 16.1708 10.319 16.1708C8.89691 16.1708 7.60687 15.6125 6.66654 14.7042L3.03906 18.2865C4.90901 20.0995 7.48197 21.2195 10.319 21.2195C15.9895 21.2195 20.5983 16.75 20.6805 11.206V10.835C20.6805 10.835 16.1412 11.682 10.2351 10.835V10.8367Z"
      fill="white"
    />
  </svg>
)
const Memo = memo(SvgIconWalletTg)
export default Memo
