import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="url(#paint0_linear_3111_2767)" height={24} rx={6} width={24} />
    <path
      d="M8.5 12.5L10.5 14.5L15.5 9.5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3111_2767"
        x1={12}
        x2={12}
        y1={0}
        y2={24}
      >
        <stop stopColor="#48CA93" />
        <stop offset={1} stopColor="#48BACA" />
      </linearGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgSuccess)
export default Memo
