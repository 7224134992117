import { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import {
  Home,
  MyWallet
} from 'pages'
import { Layout } from 'ui'
import { CreateWallet } from 'pages/CreateWallet'
import { ImportWallet } from 'pages/ImportWallet/ImportWallet'

export const App = memo(() => {
  return (
    <Routes>
      <Route Component={Layout} path={AppRoutes.Home}>
        <Route Component={Home} index />
        <Route Component={MyWallet} path={AppRoutes.MyWallet} />
      </Route>
      <Route>
        <Route Component={ImportWallet} path={AppRoutes.ImportWallet} />
        <Route Component={CreateWallet} path={AppRoutes.CreateWallet} />
      </Route>
    </Routes>
  )
})