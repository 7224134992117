import React, { useState } from 'react';
import * as S from './style'

// Define props type
interface RadioButtonsProps {
  options: string[];
  onChange: (selected: string) => void; // Function to call on change
}

export const RadioButtons: React.FC<RadioButtonsProps> = ({ options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleClick = (option: string) => {
    setSelectedOption(option);
    onChange(option); // Propagate the change up to parent component
  };

  return (
    <S.Wrapper>
      {options.map((option, index) => (
        <S.RadioButton
          key={index}
          onClick={() => handleClick(option)}
          selected={selectedOption === option}
        >
          {option}
        </S.RadioButton>
      ))}
    </S.Wrapper>
  );
};

export default RadioButtons;
