import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="60"
        viewBox="0 0 61 60"
        width="61"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5808_36154)">
            <path d="M30.5 0C13.932 0 0.5 13.432 0.5 30C0.5 46.568 13.932 60 30.5 60C47.068 60 60.5 46.568 60.5 30C60.5 13.432 47.068 0 30.5 0Z" fill="#E03757" />
            <path d="M14.5754 46.8082C32.6094 46.8082 47.2297 32.1879 47.2297 14.1539C47.2339 10.7414 46.7024 7.34935 45.6547 4.10161C41.0595 1.40702 35.827 -0.00912169 30.5 4.4213e-05C13.9321 4.4213e-05 0.500034 13.4321 0.500034 30C0.491905 35.3774 1.93654 40.6571 4.68128 45.2813C7.88112 46.2963 11.2185 46.8114 14.5754 46.8082Z" fill="#F04760" />
            <path d="M30.4875 50.3494C29.5233 50.3494 28.5807 50.0635 27.7789 49.5279C26.9772 48.9923 26.3522 48.231 25.983 47.3403C25.6138 46.4496 25.5169 45.4694 25.7047 44.5236C25.8925 43.5779 26.3564 42.709 27.0379 42.0269C27.7193 41.3448 28.5878 40.88 29.5333 40.6913C30.4789 40.5026 31.4592 40.5985 32.3503 40.9669C33.2414 41.3352 34.0032 41.9595 34.5396 42.7607C35.076 43.562 35.3627 44.5043 35.3637 45.4685C35.3643 46.1093 35.2386 46.7438 34.9938 47.336C34.7491 47.9281 34.39 48.4662 33.9371 48.9195C33.4843 49.3728 32.9465 49.7324 32.3546 49.9777C31.7627 50.2231 31.1282 50.3494 30.4875 50.3494ZM35.2137 33.5564C35.1316 34.3099 34.7913 35.0117 34.2504 35.5427C33.7024 36.0775 32.9894 36.4108 32.2277 36.4884H32.2055C31.7277 36.5431 31.2473 36.5705 30.7664 36.5705C30.19 36.5734 29.6138 36.546 29.0402 36.4884H29.0168C28.2555 36.4106 27.543 36.0772 26.9953 35.5427C26.4544 35.0117 26.1141 34.3099 26.032 33.5564C25.9148 32.456 25.8562 24.8142 25.8562 23.1115C25.8562 21.4087 25.916 13.747 26.032 12.6466C26.1128 11.8911 26.4532 11.1869 26.9953 10.6544C27.5435 10.1206 28.2557 9.78743 29.0168 9.70875H29.0402C29.6137 9.65146 30.1905 9.63384 30.7664 9.65601C31.2463 9.63381 31.7273 9.65145 32.2043 9.70875H32.2277C32.9904 9.78975 33.7033 10.1263 34.2504 10.6638C34.7925 11.1963 35.1329 11.9004 35.2137 12.656C35.3309 13.7564 35.3895 21.4193 35.3895 23.1209C35.3895 24.8224 35.3297 32.4607 35.2137 33.5564Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_5808_36154">
                <rect fill="white" height="60" transform="translate(0.5)" width="60" />
            </clipPath>
        </defs>
    </svg>
)
const Memo = memo(SvgInfoIcon)
export default Memo
