import { ApiRoutes } from "constants/api"
import { AXIOS_INSTANCE } from "libs/axios-instance/axios-instance"

export const registerUser = async (
    initData: string
) => {
    const { data } = await AXIOS_INSTANCE.post<any>(
        `${ApiRoutes.Users}`,
        { initData }
    )

    return data
}
