export const getPasswordIssue = (password: string): string | undefined => {
	const specialCharacters = "&@^$#%*!?=_-'<>~,.;:+()[]{}/";
	if (password.length < 8) {
		return `Password's minimum length is 8. Also the password must include at least one digit, one upper case character, one lower case character and one special character: ${specialCharacters}`;
	}
	if (
		!password.match(/[0-9]/) ||
		!password.match(/[A-Z]/) ||
		!password.match(/[a-z]/) ||
		!password.match(/[&@^$#%*!?=_\-'<>~,.;:+()[\]{}/]/)
	) {
		return `The password must include at least one digit, one upper case character, one lower case character and one special character: ${specialCharacters}`;
	}
};