import styled from 'styled-components'

export const Wrapper = styled.div<{ isgreen?: boolean }>`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  line-height: 12px;
  color: ${({ theme, isgreen }) => isgreen ? theme.color.green : theme.color.red}};
`
