import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={21}
    viewBox="0 0 20 21"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="inherit"
      height={3.15782}
      transform="matrix(0.703146 0.711046 -0.703146 0.711046 2.22046 0)"
      width={25.2937}
    />
    <rect
      fill="inherit"
      height={3.15782}
      transform="matrix(-0.703146 0.711046 -0.703146 -0.711046 20.0056 2.24561)"
      width={25.2937}
    />
  </svg>
)
const Memo = memo(SvgClose)
export default Memo
