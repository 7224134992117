import { FC } from 'react'
import { SvgError, SvgSuccess } from 'ui/icons'
import { ToastContainer as DefaultToastContainer } from 'react-toastify';

export const ToastContainer: FC = () => {
  return <DefaultToastContainer
    autoClose={4000}
    closeOnClick
    hideProgressBar
    icon={({ type }) => {
      if (type === 'success') return <SvgSuccess />
      if (type === 'error') return <SvgError />
      else return null
    }}
    newestOnTop
    pauseOnFocusLoss
    pauseOnHover
    position="top-center"
    rtl={false}
    theme="colored"
  />
}
