import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgImport = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={24} viewBox="0 0 25 24" width={24} xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1548 2H8.77484C5.13484 2 2.96484 4.17 2.96484 7.81V16.18C2.96484 19.83 5.13484 22 8.77484 22H17.1448C20.7848 22 22.9548 19.83 22.9548 16.19V7.81C22.9648 4.17 20.7948 2 17.1548 2Z" fill="#007AFF" opacity="0.2"/>
    <path d="M8.71484 17.5H13.5448C13.9548 17.5 14.2948 17.16 14.2948 16.75C14.2948 16.34 13.9548 16 13.5448 16H10.5248L18.2448 8.28C18.5348 7.99 18.5348 7.51 18.2448 7.22C18.0948 7.07 17.9048 7 17.7148 7C17.5248 7 17.3348 7.07 17.1848 7.22L9.46484 14.94V11.92C9.46484 11.51 9.12484 11.17 8.71484 11.17C8.30484 11.17 7.96484 11.51 7.96484 11.92V16.75C7.96484 17.16 8.30484 17.5 8.71484 17.5Z" fill="#007AFF"/>
  </svg>
)
const Memo = memo(SvgImport)
export default Memo
