import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgWallet2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 25 24"
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1001 9H7.1001"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M22.1003 10.9702V13.0302C22.1003 13.5802 21.6603 14.0302 21.1003 14.0502H19.1403C18.0603 14.0502 17.0703 13.2602 16.9803 12.1802C16.9203 11.5502 17.1603 10.9602 17.5803 10.5502C17.9503 10.1702 18.4603 9.9502 19.0203 9.9502H21.1003C21.6603 9.9702 22.1003 10.4202 22.1003 10.9702Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M17.5801 10.55C17.1601 10.96 16.9201 11.55 16.9801 12.18C17.0701 13.26 18.0601 14.05 19.1401 14.05H21.1001V15.5C21.1001 18.5 19.1001 20.5 16.1001 20.5H7.1001C4.1001 20.5 2.1001 18.5 2.1001 15.5V8.5C2.1001 5.78 3.7401 3.88 6.2901 3.56C6.5501 3.52 6.8201 3.5 7.1001 3.5H16.1001C16.3601 3.5 16.6101 3.50999 16.8501 3.54999C19.4301 3.84999 21.1001 5.76 21.1001 8.5V9.95001H19.0201C18.4601 9.95001 17.9501 10.17 17.5801 10.55Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
)
const Memo = memo(SvgWallet2)
export default Memo
