import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { BackButton } from 'features'
import { SvgInfoIcon } from 'ui/icons'
import * as S from './style'

export const CreateWalletWarning: FC = () => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
		navigate(AppRoutes.CreateWalletMnemonic);
	};
  return (
    <>
      <BackButton/>
      <S.MainBlock>
        <S.Title>
          Backup
        </S.Title>
        <S.LogoBlock>
          <SvgInfoIcon/>
          <S.Title>Warning</S.Title>
        </S.LogoBlock>
        <S.List>
          <S.ListItem>
            Please keep your mnemonic phrase secure. 
            Owning the mnemonic phrase is equivalent to owning all the rights to the wallet.
          </S.ListItem>
          <S.ListItem>
            Please do not provide the mnemonic phrase to anyone
            </S.ListItem>
          <S.ListItem>
            The GRAM Wallet will never ask for your mnemonic phrase
          </S.ListItem>
        </S.List>
      </S.MainBlock>
      <S.BottomBlock>
        <S.CheckboxWrapper>
          <S.Checkbox id="acceptCheckbox" type='checkbox'/>
          <S.CheckboxLabel htmlFor="acceptCheckbox">I understand the associated risks</S.CheckboxLabel>
        </S.CheckboxWrapper>
        <S.BtnWrapper onClick={handleSubmit}>
          Display Mnemonic Phrase
        </S.BtnWrapper>
      </S.BottomBlock>
    </>
  )
}
